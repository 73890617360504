<template>
  <!-- 左边菜单组件 -->
  <el-menu
    :default-active="activePath"
    class="el-menu-vertical-demo"
    router
    :collapse="isCollapse"
    text-color="#a1b1c5"
    background-color="#0a263e"
    active-text-color="#eee"
    unique-opened
  >
    <div v-for="nav in list" :key="nav">
      <el-submenu v-if="nav.children && nav.children.length > 0" :index="nav.title.toString()">
        <template #title>
          <i v-if="nav.icon" :class="nav.icon"></i>
          <span>{{ nav.title }}</span>
        </template>
        <template #default>
          <div v-for="navItem in nav.children" :key="navItem">
            <el-submenu
              v-if="navItem.children && navItem.children.length > 0"
              :index="navItem.title.toString()"
            >
              <template #title>
                <i v-if="navItem.icon" :class="navItem.icon"></i>
                <span>{{ navItem.title }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  :index="navSonItem.href"
                  v-for="navSonItem in navItem.children"
                  :key="navSonItem"
                >
                  <i v-if="navSonItem.icon" :class="navSonItem.icon"></i>
                  <span>{{ navSonItem.title }}</span>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item v-else :index="navItem.href">
              <i v-if="navItem.icon" :class="navItem.icon"></i>
              <span>{{ navItem.title }}</span>
            </el-menu-item>
          </div>
        </template>
      </el-submenu>
      <el-menu-item v-else :index="nav.href">
        <i v-if="nav.icon" :class="nav.icon"></i>
        <span>{{ nav.title }}</span>
      </el-menu-item>
    </div>
  </el-menu>
</template>

<script>
import { ref } from 'vue'
export default {
  name: 'MenuLeft',
  props: {
    list: {
      type: Array
    },
    activePath: {
      type: String,
      default: ''
    }
  },
  setup() {
    const isCollapse = ref(false)
    return { isCollapse }
  }
}
</script>

<style lang="scss">
.el-menu {
  box-sizing: border-box;
}
.el-menu-vertical-demo {
  border: 0 !important;
}
.el-aside {
  background-color: #304156;
  background-color: #0a263e;
  box-shadow: 3px 0 10px rgba(0, 0, 0, 0.3);
}
.el-aside-no-collapse .el-menu-item-group {
  padding: 5px 0;
  background-color: #0a263e;
  &__title {
    padding: 0 !important ;
  }
}
.el-menu-item-group {
  &__title {
    padding: 0 !important ;
  }
}
.el-submenu .el-menu-item {
  text-indent: 35px;
  // margin: 5px 0;
  background-color: #001528 !important;
  padding: 0 0 0 30px !important;
}
.el-submenu__title i {
  font-size: 15px;
  color: #eee !important;
}
.el-aside-no-collapse .el-menu-item.is-active {
  background-color: $--color-primary !important;
  color: #fff !important;
}
</style>
