/**
 * 故障管理-新版
 */

const prefix = '/dseWeb'
export default [
  // 故障记录分页查询
  {
    name: 'deviceErrorRecordsPage',
    url: `${prefix}/v1/op/newDeviceError/deviceErrorRecords`,
    method: 'get'
  },
  // 故障记录上报
  {
    name: 'addNewDeviceErrorRecord',
    url: `${prefix}/v1/op/newDeviceError/deviceErrorRecord`,
    method: 'post'
  },
  // 删除
  {
    name: 'deleteBatchErrorRecord',
    url: `${prefix}/v1/op/newDeviceError/deleteBatch`,
    method: 'post'
  },
  // 详情
  {
    name: 'deviceErrorRecordById',
    url: `${prefix}/v1/op/newDeviceError/deviceErrorRecord/`,
    method: 'get'
  },
  // 查询故障流程进度
  {
    name: 'deviceErrorLogsByError',
    url: `${prefix}/v1/op/newDeviceError/deviceErrorLogsByError`,
    method: 'get'
  }
]
