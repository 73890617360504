/**
 * 资源管理接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 资源类型树
    async fetchGetModuleResourceAllTreeList({ commit, getters }) {
      const { getModuleAllTreeList } = getters.getHttps
      const { data } = await getModuleAllTreeList()
      return setStoreApiDate(data, commit)
    },
    // 新增资源
    async fetchAddSysModule({ commit, getters }, params) {
      const { addSysModule } = getters.getHttps
      const { data } = await addSysModule(params)
      return setStoreApiDate(data, commit)
    },
    // 修改资源
    async fetchUpdateSysModule({ commit, getters }, params) {
      const { updateSysModule } = getters.getHttps
      const { data } = await updateSysModule(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个资源
    async fetchGetSysModule({ commit, getters }, resourceId) {
      const { getSysModulen } = getters.getHttps
      const { data } = await getSysModulen(false, false, {
        url: `${prefix}/v1/op/sysModule/${resourceId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 删除资源
    async fetchRemoveSysModule({ commit, getters }, resourceId) {
      const { removeSysModule } = getters.getHttps
      const { data } = await removeSysModule(false, false, {
        url: `${prefix}/v1/op/sysModule/${resourceId}`
      })
      return setStoreApiDate(data, commit)
    }
  }
}
