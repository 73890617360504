/**
 * 故障管理
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 故障记录分页查询
    async fetchDeviceErrorRecordsPage({ commit, getters }, params) {
      const { deviceErrorRecordsPage } = getters.getHttps
      const { data } = await deviceErrorRecordsPage(params)
      return setStoreApiDate(data, commit)
    },
    // 故障记录上报
    async fetchNewAddDeviceErrorRecord({ commit, getters }, params) {
      const { addNewDeviceErrorRecord } = getters.getHttps
      const { data } = await addNewDeviceErrorRecord(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个设备故障记录
    async fetchDeviceErrorRecordById({ commit, getters }, errorId) {
      const { deviceErrorRecordById } = getters.getHttps
      const { data } = await deviceErrorRecordById(false, false, {
        url: `${prefix}/v1/op/newDeviceError/deviceErrorRecord/${errorId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 删除
    async fetchDeleteBatchErrorRecord({ commit, getters }, params) {
      const { deleteBatchErrorRecord } = getters.getHttps
      const { data } = await deleteBatchErrorRecord(params)
      return setStoreApiDate(data, commit)
    },
    // 查询故障流程进度
    async fetchDeviceErrorLogsByError({ commit, getters }, params) {
      const { deviceErrorLogsByError } = getters.getHttps
      const { data } = await deviceErrorLogsByError(params)
      return setStoreApiDate(data, commit)
    }
  }
}
