<template>
  <!--
    @name: CustomUpload
    @describe: 上传文件
    @author: L
    @date: 2021-05-28
 -->
  <div>
    <el-upload
      class="ml-upload"
      :class="{ 'ml-upload-disabled': updateDisabled }"
      :data="data"
      :headers="headers"
      :action="action"
      :disabled="updateDisabled"
      :name="name"
      :multiple="multiple"
      :auto-upload="autoUpload"
      :limit="limit"
      :show-file-list="showFileList"
      :on-progress="uploadVideoProcess"
      :before-upload="beforeUpload"
      :file-list="fileList"
      :accept="accept"
      :list-type="listType"
      :on-change="onChange"
      :on-success="onSuccess"
      ref="CustomUploadRef"
    >
      <slot><i class="el-icon-plus"></i></slot>
      <template #file="{ file }">
        <div style="width: 100%; height: 100%">
          <video
            style="object-fit: scale-down"
            v-if="isFileTVideoype(file)"
            class="el-upload-list__item-thumbnail"
            :src="formatUrl(file.url)"
          ></video>
          <img
            style="object-fit: scale-down"
            v-else
            class="el-upload-list__item-thumbnail"
            :src="formatUrl(file.url)"
          />
          <span class="el-upload-list__item-actions">
            <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
              <i class="el-icon-zoom-in"></i>
            </span>
            <span
              v-if="!updateDisabled && file.url.indexOf('blob:') !== 0"
              class="el-upload-list__item-delete"
              @click="handleDownload(file)"
            >
              <i class="el-icon-download"></i>
            </span>
            <span
              v-if="!updateDisabled && !isDetails"
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
            >
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </template>
      <template #tip v-if="tip">
        <div class="el-upload__tip">{{ tip }}</div>
      </template>
    </el-upload>
    <!-- 查看图片 -->
    <ml-dialog
      :width="`${viewWidth / 1.4}px`"
      ref="pictureViewRef"
      customClass="pictureView"
      :showBtn="false"
    >
      <template #body>
        <video
          controls
          autoplay
          style="width: 100%; height: 100%"
          v-if="pictureViewData.type"
          :src="pictureViewData.url"
        />
        <el-image
          style="width: 100%; height: 100%"
          fit="contain"
          v-else
          :src="pictureViewData.url"
        />
      </template>
    </ml-dialog>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch, toRefs } from 'vue'
import { useStore } from 'vuex'
import { BASEURL, FILEPREVIEWURL, FILEDOWNLOAD } from '@/API'
export default {
  name: 'CustomUpload',
  // emits: ['on-change'],
  props: {
    // 提交路径
    action: {
      type: String,
      default: '#'
    },
    // 上传时附带的额外参数
    data: {
      type: Object
    },
    // 设置上传的请求头部
    headers: {
      type: Object
    },
    // 是否支持多选
    multiple: {
      type: Boolean,
      default: false
    },
    // 是否选择文件后立即上传
    autoUpload: {
      type: Boolean,
      default: false
    },
    // // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否显示已上传文件列表
    showFileList: {
      type: Boolean,
      default: true
    },
    // 上传字段名
    name: {
      type: String
    },
    // 上传类型
    accept: {
      type: String
    },
    // 文件列表的类型
    listType: {
      type: String
    },
    // 提示说明文字
    tip: {
      type: String,
      default: ''
    },
    // 最大允许上传个数
    limit: {
      type: Number
    },
    // 上传的文件列表
    fileLists: {
      type: Array,
      default: () => []
    },
    // 是否详情页面
    isDetails: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    // 文件最大大小
    maxSize: {
      type: Number,
      default: 0
    }
  },
  setup(props, content) {
    
    const { commit } = useStore()
    const CustomUploadRef = ref()
    const fileList = ref(props.modelValue || props.fileLists)
    const updateDisabled = ref(true)
    watch(
      () => props.modelValue,
      newvalue => {
        fileList.value = newvalue
      }
    )
    watch(
      () => props.disabled,
      newvalue => {
        updateDisabled.value = newvalue
      },
      {
        immediate: true
      }
    )
    watch(
      () => props.fileLists,
      newvalue => {
        fileList.value = newvalue
      }
    )
    // 文件列表是否等于最大限制
    const fileListLenIsSamelimit = (tiime = 0) => {
      if (!props.listType) {
        return false
      } else if (
        updateDisabled.value ||
        (props.limit > 0 && CustomUploadRef.value.uploadFiles.length >= parseInt(props.limit))
      ) {
        setTimeout(() => {
          CustomUploadRef.value.$el.querySelector('.el-upload--picture-card').style.display = 'none'
        }, tiime)
      } else {
        setTimeout(() => {
          CustomUploadRef.value.$el.querySelector('.el-upload--picture-card').style.display =
            'inline-block'
          CustomUploadRef.value.$el.querySelector('.el-upload--picture-card').style.marginBottom =
            '13px'
        }, tiime)
      }
    }
    // 文件改变
    const onChange = (response, file, fileList) => {
      content.emit('on-change', response, file, fileList)
      fileListLenIsSamelimit()
    }
    const onSuccess = (data, file, fileList) => {
      content.emit('on-success', data, file, fileList)
      fileListLenIsSamelimit()
    }
    const uploadVideoProcess = (ev, file, fileList) => {
      content.emit('on-progress', ev, file, fileList)
    }
    // 格式处理
    const isFileTVideoype = file => {
      const type = file.name.substr(file.name.lastIndexOf('.'))
      return type === '.mp4' || type === '.rmvb' || type === '.mpeg' || type === '.avi'
    }
    // 查看图片
    const pictureViewRef = ref()
    const pictureViewData = reactive({
      url: '',
      type: true // 是否mp4格式
    })
    // 查看图片
    const handlePictureCardPreview = file => {
      pictureViewData.url = formatUrl(file.url)
      pictureViewData.type = isFileTVideoype(file)
      pictureViewRef.value.showDialog = true
    }
    // 下载文件
    const handleDownload = file => {
      location.href = `${FILEDOWNLOAD}/${file.url}`
    }
    // 删除文件
    const handleRemove = file => {
      // CustomUploadRef.value.uploadFiles.map((item, index) => {
      //   if (item.uid === file.uid) {
      //     CustomUploadRef.value.uploadFiles.splice(index, 1)
      //   }
      // })
      // let fileItem = CustomUploadRef.value.uploadFiles.find(item => {
      //   return item.uid === file.uid
      // })
      content.emit('on-remove', { data: file })
      // content.emit('on-success', { data: CustomUploadRef.value.uploadFiles })
      fileListLenIsSamelimit()
    }
    // 格式化路径
    const formatUrl = url => {
      // return url
      return url.indexOf('blob:') === 0
        ? url
        : `${BASEURL || 'https://yw.dse.cn'}${FILEPREVIEWURL}/${url}`
    }
    const viewWidth = ref(Math.min(document.body.offsetWidth, document.body.offsetHeight))
    const httpRequest = file => {
      content.emit('http-request', file)
    }
    const beforeUpload = file => {
      content.emit('before-upload', file)
      let fileSize = file.size
      if( props.maxSize && fileSize > 1024 * 1024 * props.maxSize) {
        
        commit('setError', {
          status: true,
          title: '',
          message: '文件必须小于'+ props.maxSize +'M' ,
          type: 'waring'
        })
        return false
      }
      return true
    }
    onMounted(() => {
      window.addEventListener('resize', () => {
        viewWidth.value = Math.min(document.body.offsetWidth, document.body.offsetHeight)
      })
      fileListLenIsSamelimit()
      watch(
        () => CustomUploadRef.value.uploadFiles,
        newvalue => {
          content.emit('update:modelValue', newvalue)
          fileListLenIsSamelimit()
        }
      )
    })
    return {
      CustomUploadRef,
      onChange,
      isFileTVideoype,
      fileListLenIsSamelimit,
      pictureViewRef,
      pictureViewData,
      handlePictureCardPreview,
      handleDownload,
      handleRemove,
      formatUrl,
      viewWidth,
      onSuccess,
      httpRequest,
      uploadVideoProcess,
      fileList,
      beforeUpload,
      updateDisabled
    }
  }
}
</script>

<style lang="scss">
.ml-upload {
  line-height: 20px;
  .el-upload--picture-card,
  .el-upload-list--picture-card .el-upload-list__item {
    border-style: dashed;
    transition: none !important;
    &:focus {
      outline: none;
      // border-color: $green-500;
    }
  }
}
.pictureView {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    // max-height: calc(100vh / 1.5);
  }
}
.ml-upload-disabled .el-upload {
  display: none !important;
}
.ml-upload-disabled .el-upload__tip {
  display: none !important;
}
</style>
