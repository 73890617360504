/**
 * 项目流程关系绑定接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 查询列表（分页）
    async fetchProjectProcessPage({ commit, getters }, params) {
      const { projectProcessPage } = getters.getHttps
      const { data } = await projectProcessPage(params)
      return setStoreApiDate(data, commit)
    },
    // 新增或修改
    async fetchInsetProjectProcess({ commit, getters }, params) {
      const { insetProjectProcess } = getters.getHttps
      const { data } = await insetProjectProcess(params)
      return setStoreApiDate(data, commit)
    },
    // 删除
    async fetchDeleteProjectProcess({ commit, getters }, params) {
      const { deleteProjectProcess } = getters.getHttps
      const { data } = await deleteProjectProcess(params)
      return setStoreApiDate(data, commit)
    },
    // 详情
    async fetchProjectProcessInfo({ commit, getters }, params) {
      const { projectProcessInfo } = getters.getHttps
      const { data } = await projectProcessInfo(params)
      return setStoreApiDate(data, commit)
    },
    // 流程列表查询
    async fetchDefinitionList({ commit, getters }, params) {
      const { definitionList } = getters.getHttps
      const { data } = await definitionList(params)
      return setStoreApiDate(data, commit)
    }
  }
}
