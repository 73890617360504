/**
 * 项目流程关系绑定
 */

const prefix = '/dseWeb'
export default [
  // 查询列表（分页）
  { name: 'projectProcessPage', url: `${prefix}/v1/op/projectProcess/page`, method: 'post' },
  // 新增或修改
  {
    name: 'insetProjectProcess',
    url: `${prefix}/v1/op/projectProcess/insetOrUpdate`,
    method: 'post'
  },
  // 删除
  { name: 'deleteProjectProcess', url: `${prefix}/v1/op/projectProcess/delete`, method: 'get' },
  // 详情
  { name: 'projectProcessInfo', url: `${prefix}/v1/op/projectProcess/info`, method: 'get' },
  // 详情
  { name: 'definitionList', url: `${prefix}/v1/op/wfp/definitionList`, method: 'get' }
]
