/**
 * 工作流管理
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 流程列表查询
    async fetchDefinitionListRequest({ commit, getters }, params) {
      const { definitionListRequest } = getters.getHttps
      const { data } = await definitionListRequest(params)
      return setStoreApiDate(data, commit)
    },
    // 查询任务节点的配置
    async fetchGetTaskConfRequest({ commit, getters }, params) {
      const { getTaskConfRequest } = getters.getHttps
      const { data } = await getTaskConfRequest(params)
      return setStoreApiDate(data, commit)
    },
    // 点击流程按钮执行操作
    async fetchButtonClickRequest({ commit, getters }, params) {
      const { buttonClickRequest } = getters.getHttps
      const { data } = await buttonClickRequest(params)
      return setStoreApiDate(data, commit)
    }
  }
}
