/**
 * 设备管理接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询设备信息
    async fetchGetDeviceInfos({ commit, getters }, params) {
      const { getDeviceInfos } = getters.getHttps
      const { data } = await getDeviceInfos(params)
      return setStoreApiDate(data, commit)
    },
    // 批量删除设备信息
    async fetchDeleteDeviceInfos({ commit, getters }, params) {
      const { deleteDeviceInfos } = getters.getHttps
      const { data } = await deleteDeviceInfos(params, false, {
        body: true
      })
      return setStoreApiDate(data, commit)
    },
    // 新增设备信息
    async fetchAddDeviceInfo({ commit, getters }, params) {
      const { addDeviceInfo } = getters.getHttps
      const { data } = await addDeviceInfo(params)
      return setStoreApiDate(data, commit)
    },
    // 修改设备信息
    async fatchUpdateDeviceInfo({ commit, getters }, params) {
      const { updateDeviceInfo } = getters.getHttps
      const { data } = await updateDeviceInfo(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个设备信息
    async fetchGetDeviceInfo({ commit, getters }, deviceId) {
      const { getDeviceInfo } = getters.getHttps
      const { data } = await getDeviceInfo(false, false, {
        url: `${prefix}/v1/op/deviceInfo/${deviceId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 删除设备信息
    async fatchDeleteDeviceInfo({ commit, getters }, deviceId) {
      const { deleteDeviceInfo } = getters.getHttps
      const { data } = await deleteDeviceInfo(false, false, {
        url: `${prefix}/v1/op/deviceInfo/${deviceId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 设备信息Excel模板导入
    async fetchDeviceExcelImport({ commit, getters }, params) {
      const { deviceExcelImport } = getters.getHttps
      const { data } = await deviceExcelImport(params, true)
      return setStoreApiDate(data, commit)
    },
    // 设备信息Excel模板导出
    async fetchDeviceExcelExport({ commit, getters }, params) {
      const { deviceExcelExport } = getters.getHttps
      const { data } = await deviceExcelExport(params, true)
      return setStoreApiDate(data, commit)
    },
    // 创建设备二维码
    async fetchCreateDeviceQrCode({ commit, getters }, params) {
      const { createDeviceQrCode } = getters.getHttps
      const { data } = await createDeviceQrCode(params)
      return setStoreApiDate(data, commit)
    },
    // 查询项目设备图层树集合
    async fetchProjectDeviceTreeList({ commit, getters }, params) {
      const { projectDeviceTreeList } = getters.getHttps
      const { data } = await projectDeviceTreeList(params)
      return setStoreApiDate(data, commit)
    },
    // 根据阿米巴 查询 子级数据
    async fetchProjectByCompanyIdDeviceTree({ commit, getters }, params) {
      const { projectByCompanyIdDeviceTree } = getters.getHttps
      const { data } = await projectByCompanyIdDeviceTree(params)
      return setStoreApiDate(data, commit)
    },

    // 更新Iot产品属性
    async fetchUpdateIotProducts({ commit, getters }, params) {
      const { updateIotProducts } = getters.getHttps
      const { data } = await updateIotProducts(params)
      return setStoreApiDate(data, commit)
    },
    // 查询项目设备图层树集合 - new
    async fetchGetDeviceBases({ commit, getters }, params) {
      const { getDeviceBases } = getters.getHttps
      const { data } = await getDeviceBases(params)
      return setStoreApiDate(data, commit)
    },
    // 查询项目设备图层树集合 - new
    async fetchGetDeviceBasesStatisticsVo({ commit, getters }, params) {
      const { getDeviceBasesStatisticsVo } = getters.getHttps
      const { data } = await getDeviceBasesStatisticsVo(params)
      return setStoreApiDate(data, commit)
    },
    // 获取设备最新属性清单列表
    async fetchLastRtuAttrList({ commit, getters }, params) {
      const { lastRtuAttrList } = getters.getHttps
      const { data } = await lastRtuAttrList(params)
      return setStoreApiDate(data, commit)
    },
    // 获取设备最新属性清单列表
    async fetchRtuAttrHisList({ commit, getters }, params) {
      const { rtuAttrHisList } = getters.getHttps
      const { data } = await rtuAttrHisList(params)
      return setStoreApiDate(data, commit)
    },
    // 获取设备属性历史分页
    async fetchRtuAttrHisListPage({ commit, getters }, params) {
      const { rtuAttrHisPage } = getters.getHttps
      const { data } = await rtuAttrHisPage(params)
      return setStoreApiDate(data, commit)
    },
    // 导出设备属性历史数据列表
    async fetchExportRtuAttrHis({ commit, getters }, params) {
      const { exportRtuAttrHis } = getters.getHttps
      const { data } = await exportRtuAttrHis(params)
      return data
    },
    // 查询项目设备图层树集合
    async fetchDeviceTreeList({ commit, getters }, params) {
      const { deviceTree } = getters.getHttps
      const { data } = await deviceTree(params)
      return setStoreApiDate(data, commit)
    },
  }
}
