<template>
  <div class="iframe-view">
    <iframe
      v-if="iframeOpenType === '0'"
      ref="iframeRef"
      :src="iframeLink"
      class="dse-content"
      allowfullscreen="allowfullscreen"
      name="dynamicContainer"
    ></iframe>
    <div class="not-file" v-else>
      <div>
        <el-image :src="notFileImg" />
        <div class="file-text">
          <span>这是一个跳转页面，点击按钮跳转至页面</span>
          <el-button style="mini" type="primary" @click="go">
            跳转 <el-icon class="el-icon-right"></el-icon>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { onMounted } from 'vue'
export default {
  name: 'iframeView',
  setup() {
    const route = useRoute()
    const { iframeOpenType, iframeLink } = route.meta
    const notFileImg = require('@/assets/image/404.9ed13bbe.svg')
    const go = () => {
      window.open(iframeLink, '_blank')
    }
    onMounted(() => {
      if (iframeOpenType === '1') {
        go()
      }
    })
    return {
      route,
      iframeOpenType,
      iframeLink,
      notFileImg,
      go
    }
  }
}
</script>
<style lang="scss" scoped>
.iframe-view {
  width: 100%;
  height: 100%;
  min-width: 500px;
}
.dse-content {
  width: 100%;
  height: 100%;
}
.not-file {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 0 5px 5px #eee;
    margin: auto;
    .el-image {
      width: 300px;
      height: 300px;
      padding: 15px;
    }
    .file-text {
      padding: 15px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      h1 {
        font: {
          size: 65px;
          weight: 700;
        }
        margin: 0;
        margin-bottom: 40px;
      }
      > span {
        font-size: 15px;
        margin-bottom: 25px;
      }
    }
  }
}
</style>
