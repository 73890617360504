/**
 * 资源管理
 */

const prefix = '/dseWeb'
export default [
  // 资源类型树
  { name: 'getModuleAllTreeList', url: `${prefix}/v1/op/moduleAllTreeList`, method: 'get' },
  // 新增资源
  { name: 'addSysModule', url: `${prefix}/v1/op/sysModule`, method: 'post' },
  // 修改资源
  { name: 'updateSysModule', url: `${prefix}/v1/op/sysModule`, method: 'put' },
  // 查询单个资源
  { name: 'getSysModulen', url: `${prefix}/v1/op/sysModule`, method: 'get' },
  // 删除资源
  { name: 'removeSysModule', url: `${prefix}/v1/op/sysModule`, method: 'delete' },
  // 资源类型列表
  { name: 'getModuleTypes', url: `${prefix}/v1/op/moduleTypes`, method: 'get' }
]
