<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>
</template>

<script>
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ElMessage, ElNotification } from 'element-plus'

import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default {
  name: 'App',
  components: {
    [ElConfigProvider.name]: ElConfigProvider //添加组件
  },
  data() {
    return {
      locale: zhCn //给locale赋值
    }
  },
  setup() {
    const { getters } = useStore()
    const router = useRouter()
    const err = computed(() => getters.getError)
    const userInfo = computed(() => getters.getUser)
    watch(
      () => userInfo.value,
      newvalue => {
        if (!newvalue.isLogin) {
          router.push({ path: '/login' })
        }
      }
    )
    // 监听错误状态，并触发提示框
    watch(
      () => err.value,
      () => {
        if (err.value.status) {
          if (
            document.getElementsByClassName('el-message').length <= 0 &&
            document.getElementsByClassName('el-notification').length <= 0
          ) {
            console.log(4434, err.value)
            if (err.value.type === 'success' && err.value.title) {
              ElNotification({
                title: err.value.title,
                message: err.value.message,
                type: 'success'
              })
            } else {
              ElMessage({ message: err.value.message, type: err.value.type || 'warning' })
            }
          }
        }
      }
    )
  }
}
</script>

<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0 !important;
}
#app {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei',
    '微软雅黑', Arial, sans-serif;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
}
// 分页
.el-pagination {
  margin-top: 20px;
}
// 滚动条
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}
::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
  background-color: #e2e2e2;
}
::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: rgba(7, 53, 92, 0.2);
}
::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
  background-color: #e2e2e2;
}
</style>
