<template>
  <!--
    @name: CustomList
    @describe: 列表
    @author: L
    @date: 2021-08-26
 -->
  <div class="custom-list">
    <div v-for="(item, index) in nList" :key="item">
      <slot>
        <div class="custom-list-div">
          <el-avatar
            shape="square"
            v-if="icon"
            :src="setIcon(item)"
            :style="{ marginRight: '10px', background: '#fff' }"
          />
          <span class="custom-list-text">{{ fileName ? item[fileName] : item['name'] }}</span>
        </div>
        <el-avatar
          :size="30"
          shape="square"
          icon="el-icon-delete"
          :style="{ border: '#F56C6C', background: '#F56C6C', cursor: 'pointer' }"
          v-if="showDelete"
          @click="clickDelete(index)"
        />
        <el-avatar
          :size="30"
          shape="square"
          icon="el-icon-download"
          :style="{
            border: '#409EFF',
            background: '#409EFF',
            cursor: 'pointer',
            marginLeft: '10px'
          }"
          v-if="showDown"
          @click="clickDown(item)"
        />
        <!-- <el-button
          size="mini"
          v-if="showDelete"
          type="danger"
          icon="el-icon-delete"
          @click="clickDelete(index)"
        ></el-button> -->
        <!-- <el-button
          size="mini"
          v-if="showDown"
          type="primary"
          icon="el-icon-download"
          @click="clickDown(item)"
        ></el-button> -->
      </slot>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { FILEDOWNLOAD } from '@API'
export default {
  name: 'CustomList',
  emits: ['click-delete'],
  props: {
    list: {
      type: Array,
      default: () => []
    },
    fileName: String,
    showDown: {
      type: Boolean,
      default: false
    },
    showDelete: {
      type: Boolean,
      default: true
    },
    icon: {
      type: Boolean,
      default: true
    }
  },
  setup(props, context) {
    const nList = computed({
      get: () => props.list,
      set: list => {
        context.emit('update:list', list)
      }
    })
    // 点击删除
    const clickDelete = index => {
      nList.value.splice(index, 1)
      context.emit('update:list', nList)
    }
    // 点击下载
    const clickDown = item => {
      if (item && item.filePath) {
        window.location.href = `${FILEDOWNLOAD}/${item.filePath}`
      }
    }

    // 获取图标
    const setIcon = item => {
      const fileName = props.fileName ? item[props.fileName] : item['name']
      let fileType = ''
      if (fileName) {
        fileType = fileName.substring(fileName.lastIndexOf('.') + 1)
        switch (fileType) {
          case 'jpg':
            fileType = icons.img
            break
          case 'jpeg':
            fileType = icons.img
            break
          case 'png':
            fileType = icons.img
            break
          case 'gif':
            fileType = icons.img
            break
          case 'doc':
            fileType = icons.w
            break
          case 'xlsx':
            fileType = icons.x
            break
          case 'xls':
            fileType = icons.x
            break
          case 'txt':
            fileType = icons.t
            break
          case 'rar':
            fileType = icons.rar
            break
          case 'zip':
            fileType = icons.rar
            break
          default:
            fileType = icons.f
            break
        }
      } else {
        fileType = icons.f
      }
      return fileType
    }

    // 文件类型
    const icons = {
      f: require('../assets/image/file/f.png'),
      img: require('../assets/image/file/img.png'),
      rar: require('../assets/image/file/rar.png'),
      t: require('../assets/image/file/t.png'),
      w: require('../assets/image/file/w.png'),
      x: require('../assets/image/file/x.png')
    }

    return { clickDelete, clickDown, nList, setIcon }
  }
}
</script>

<style lang="scss">
.custom-list {
  width: 100%;
  > div {
    margin: 0 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed #d8d8d8;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &-div {
    flex: 1;
    display: flex;
    align-items: center;
  }
  &-text {
    display: inline-block;
    max-width: calc(350px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
    margin-right: 10px;
  }
}
</style>
