/**
 * 小程序用户接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询小程序用户
    async fetchGetAppUsers({ commit, getters }, params) {
      const { getAppUsers } = getters.getHttps
      const { data } = await getAppUsers(params)
      return setStoreApiDate(data, commit)
    },
    // 新增小程序用户
    async fetchAddAppUser({ commit, getters }, params) {
      const { addAppUser } = getters.getHttps
      const { data } = await addAppUser(params)
      return setStoreApiDate(data, commit)
    },
    // 修改小程序用户
    async fetchUpdateAppUser({ commit, getters }, params) {
      const { updateAppUser } = getters.getHttps
      const { data } = await updateAppUser(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个小程序用户
    async fetchGetAppUser({ commit, getters }, userId) {
      const { getAppUser } = getters.getHttps
      const { data } = await getAppUser(false, false, {
        url: `${prefix}/v1/op/appUser/${userId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 删除小程序用户
    async fetchDeleteAppUser({ commit, getters }, userId) {
      const { deleteAppUser } = getters.getHttps
      const { data } = await deleteAppUser(false, false, {
        url: `${prefix}/v1/op/appUser/${userId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 重置密码
    async fetchResetAppUserPass({ commit, getters }, userId) {
      const { resetAppUserPass } = getters.getHttps
      const { data } = await resetAppUserPass(false, false, {
        url: `${prefix}/v1/op/resetAppUserPass/${userId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 获取指派人
    async fetchGetGanagerEngineers({ commit, getters }, params) {
      const { getGanagerEngineers } = getters.getHttps
      const { data } = await getGanagerEngineers(params)
      return setStoreApiDate(data, commit)
    },
    async fetchGetResourceTypeModule({ commit, getters }) {
      const { getModuleTypes } = getters.getHttps
      const { data } = await getModuleTypes()
      return setStoreApiDate(data, commit)
    }
  }
}
